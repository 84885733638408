import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBasemaps } from '../../../state/features/basemapSlice';
import { setLoginStatus } from '../../../state/features/loginSlice';
import { useAppSelector } from '../../../state/hooks';
import { BasemapRestApi } from '../../../util/restapi/basemap';
import { LayerRestApi } from '../../../util/restapi/layer';
import { ButtonState } from '../../shared/button-state/ButtonState';
import { DigiGeomEdit } from '../shared/digitiziation/digi-draw/DigiGeomEdit';
import { DigiGeomDelete } from '../shared/digitiziation/digi-draw/DigiGeomDelete';
import { Digitization } from '../shared/digitiziation/Digitiziation';
import { Draw } from './map/draw/Draw';
import { Map } from './map/Map'
import { SelectedFeature } from './map/selected-feature/SelectedFeature';
import { Tile } from './map/tile-layer/Tile';
import { WmsLayer } from './map/wms-layer/WmsLayer';
import WmsInfo from './map/wms-info/WmsInfo';
import PanoLook from '../panorama-container/pano-look/PanoLook';
import { Layer, LayerGroup, Project } from '../../../util/model/project';
import { RootState } from '../../../state/store';
import { OfflineLayer } from './map/offline-layer/OfflineLayer';
import { setSelectedFeature } from '../../../state/features/selectedFeatureSlice';
import { setSelectedLayerInfo } from '../../../state/features/infoSlice';
import WfsLayer from './map/wfs-layer/WfsLayer';
import { DrawStateType } from '../../../util/model/drawState';
import PanoLook2 from '../panorama-container/pano-look/PanoLook2';
import PanoLook3 from '../panorama-container/pano-look/PanoLook3';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import { OwsLayer } from './map/ows-layer/OwsLayer';
import { useTranslation } from 'react-i18next';
import { Basemap } from '../../basemap/Basemap';
import { Cursor } from './map/cursor/Cursor';
import { setSelectedLayer, setSelectedLayerGeoserverUrl, setWorkspace } from '../../../state/features/layerSlice';
import { FeatureRestApi } from '../../../util/restapi/features';
import { features } from 'process';
import BoxSelection from './map/selected-feature/BoxSelection';
import { setButtonState } from '../../../state/features/buttonStateSlice';


interface Props {
  tableVisibility: any,
  showMessage: any
}
export const MapContainer = (props : Props) => {
  const centerX = 3225922.8857;
  const centerY = 5017392.4652;
  const zoom = 15;
const showMessage = props.showMessage
const tableVisibility = props.tableVisibility
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const basemapsArray = useAppSelector(state => state.basemaps.value);
  const selectedFeatures: any = useAppSelector(state => state.selectedFeature.feature);
  const panoramaEditStatus: any = useAppSelector(state => state.digitization.panoramaEditStatus);
  const vertexFeature: any = useAppSelector(state => state.selectedFeature.vertexFeature);
  const services: any = useAppSelector(state => state.layer.services);
  const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
  const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer)
  const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects);
  const extent = [2737541.962661694, 4098882.270688692, 5123013.687827613, 5292860.5664618425]
  const selectedTableRow = useAppSelector(state => state.table.selectedRow);
  const visibleDomains = useAppSelector(state => state.layer.visibleDomains)
  const drawState = useAppSelector((state) => state.drawState.value);
  const isMultiOpen = useAppSelector(state => state.panorama.isMultiOpen);
  const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature)
  const [project2, setProject2] = useState<any>([]);
  const [visibleListBox, setVisibleListBox] = useState(false);
  const [featureList, setFeatureList] = useState<any>([]);
  const [pixel, setPixel] = useState<any>([]);
  const cursorVisible: any = useAppSelector(state => state.panorama.cursorVisible)
  const layerNodes: any = useAppSelector(state => state.layer.layerNodes);
  const childKey:any = useAppSelector(state => state.panorama.childKey);

  const layergroups:any = useAppSelector(state => state.layer.layerGroups)  /* to to match selected layer group  with the services group */


  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [shiftPressed, setShiftPressed] = useState(false);


   // to prevent the intersection between BoxSelection click interaction and OnWms Click interaction
   useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Control') setCtrlPressed(true);
      if (e.key === 'Shift') setShiftPressed(true);
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Control') setCtrlPressed(false);
      if (e.key === 'Shift') setShiftPressed(false);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);


/*   useEffect(() => {

    if(selectedLayer.name === 'panogps'){

       dispatch(setButtonState(ButtonState.NONE));
 
    }

    
  },[selectedLayer]);
 */


  useEffect(() => {
    try {
      BasemapRestApi.getBasemaps().then((res: any) => {
        const basemaps = res.data.basemaps;
        const sortedBasemaps = basemaps.sort((a: any, b: any) => a.priority - b.priority)
        const selectedBasemap = sortedBasemaps.findIndex((basemap: any) => basemap);
        basemaps[selectedBasemap].visible = true;
        dispatch(setBasemaps(basemaps));
      }).catch((error: any) => {
        // console.error(error);
      })
    } catch (error) {
      dispatch(setLoginStatus('LOGOUT'))
      localStorage.removeItem('token')
    }
  }, [])

  useEffect(() => {
    //her projenin urli farklı, servicecsten bulunup eklendi
    if (services && services.length > 0) {
      let url = '';
      let externalUrl = '';
      const copyProjects: any = [...projects]
      services.map((service: any) => {
        copyProjects.map((project: any, index: any) => {
          if (project.layer_groups && project.layer_groups.length > 0) {
            //bura kulanılmıyo
            project.layer_groups.map((layerGroup: any) => {
              layerGroup.layers.map((layer: any) => {
                url = ''
                externalUrl = ''
                if (layer.service_id === service.id) {
                  if (service.type === 'VECTOR') {
                    url = service.url
                  }
                  if (service.type === 'EXTERNAL_LAYER') {
                    externalUrl = service.url.slice(0, -3);

                  }
                  copyProjects[index] = { ...copyProjects[index], url, externalUrl, tag: service.type }
                  // copyProjects[index].layer_groups[i2] = { ...copyProjects[index], url, externalUrl, tag: service.type }

                }
              })
            })
          }

        })
      })
      setProject2(copyProjects)
    }
  }, [services, projects])

  const onWmsSelected = async (arr: any, pixel: number[]) => {

    if (ctrlPressed ||shiftPressed){
      return;
    }


    setPixel(pixel)
    if (!selectedTableRow) {
      if (arr.length === 0) {
        // setSelectedCoordinate(null);
        // setSelectedFeatures([])
        return;
      }

      const result = await LayerRestApi.getGeojsonFromGetFeatureInfoUrlArray(arr.map((a: any) => a.url));
      if (result) {
        const featureResult: any = result
          .filter((res:any) => res && res.length > 0)
          .map((features, i) => {
            const wms = arr[i].wms
            projects.find((project: Project) => {
              project.layer_groups.find((layerGroup: LayerGroup) => {
                layerGroup.layers.map((layer: Layer) => {
                  if (layer.id === wms.get('serviceId')) {
                    dispatch(setSelectedLayerInfo(layer))
                    dispatch(setSelectedLayer(layer))
                  }
                })
              })!
            })
            return { features }
          });

        const featureArr = featureResult.map((feature: any) => { return feature.features.map((ff: any) => { return ff }) })
        const features = featureArr.flat();
        if (features.length > 0) {

          if (features.length > 1) {
            let layerAlias: any
            const featuresArr = features.map((obj: any) => {
              let layerName = obj.id.split(".")[0];
              projects.find((project: any) => {
                project.layer_groups.find((service: any) => {
                  service.layers.find((layer: any) => {
                    if (layer.name === layerName) {
                      layerAlias = layer.alias
                    }
                  })
                })
              })

              return {
                name: layerAlias + " (" + obj.id.split(".")[1] + ")",
                value: obj,
              };
            });
            setFeatureList(featuresArr)
            setVisibleListBox(true);

            const selectedFeature = features[0]
            if ((buttonState === ButtonState.VERTEX || buttonState === ButtonState.DELETE || buttonState === ButtonState.EDIT || buttonState === ButtonState.MOVE) && selectedFeature.id.split('.')[0] !== selectedLayer.name) {
              // dispatch(setSelectedLayer())
               layerNodes?.map((node: any) => {
                if (node.children) {
                  node.children.map((child: any) => {
                    child.children.find((child2: any) => {
                      if (child2.name === selectedFeature.id.split('.')[0]) {
                        if (child2) {
                          const findLayerType = services.find((service: any) => service.id === child2.service_id).type
                          const url = services.find((service: any) => service.id === child2.service_id)?.url
                          const workspace = services.find((service: any) => service.id === child2.service_id).workspace;
                          const service = services.find((service: any) => service.id === child2.service_id)
                          const layerPerm = service?.layers.find((layer: any) => child2.id === layer.id)?.perm
                          //  child2.perm = layerPerm
                          const cloneObject = { ...child2 }//JSON.parse(JSON.stringify(child2));
                          cloneObject.perm = layerPerm
          
                          if (findLayerType !== 'EXTERNAL_LAYER' && findLayerType !== 'RASTER') {
                            dispatch(setSelectedLayerGeoserverUrl(url));
                            dispatch(setWorkspace(workspace));
                           /*  dispatch(setSelectedLayer(cloneObject)) */

                          } else return
                        }
                      }
                    })
                  })
                }
              }) 
         //      showMessage(t('TEXT.Continue Your Process On The Selected Layer!'))
            } 


          } else if (features.length === 1) {
            const selectedFeature = features[0]
            if ((buttonState === ButtonState.VERTEX || buttonState === ButtonState.DELETE || buttonState === ButtonState.EDIT || buttonState === ButtonState.MOVE) /*&& selectedFeature.id.split('.')[0] !== selectedLayer.name*/) {
              // dispatch(setSelectedLayer())
               layerNodes?.map((node: any) => {
                if (node.children) {
                  node.children.map((child: any) => {
                    child.children.find((child2: any) => {
                      if (child2.name === selectedFeature.id.split('.')[0]) {
                        if (child2) {
                          const findLayerType = services.find((service: any) => service.id === child2.service_id).type
                          const url = services.find((service: any) => service.id === child2.service_id)?.url
                          const workspace = services.find((service: any) => service.id === child2.service_id).workspace;
                          const service = services.find((service: any) => service.id === child2.service_id)
                          const layerPerm = service?.layers.find((layer: any) => child2.id === layer.id)?.perm
                          //  child2.perm = layerPerm
                          const cloneObject = { ...child2 }//JSON.parse(JSON.stringify(child2));
                          cloneObject.perm = layerPerm
          
                          if (findLayerType !== 'EXTERNAL_LAYER' && findLayerType !== 'RASTER') {
                            dispatch(setSelectedLayerGeoserverUrl(url));
                            // console.log("selectedFeature :",selectedFeature);
                            FeatureRestApi.getFeatures(url, cloneObject.name + `&FEATUREID=${selectedFeature.id}`).then((res: any) => {
                              
                              if(res?.data?.features.length){
                                // console.log(res.data.features[0]);
                                dispatch(setSelectedFeature([res.data.features[0]]))
                              }
                            })  

                            dispatch(setWorkspace(workspace));
                            // console.log("cloneObject:",cloneObject);
                            dispatch(setSelectedLayer(cloneObject))

                            // dispatch(setSelectedFeature([selectedFeature]))
                          } else return
                        }
                      }
                    })
                  })
                }
              }) 
         //      showMessage(t('TEXT.Continue Your Process On The Selected Layer!'))
            } else {
              dispatch(setSelectedFeature([selectedFeature]))
            }
          }
        }
      }
    }
  }

  const selectFeature = (feature: any) => {    
    dispatch(setSelectedFeature([feature]))
    setVisibleListBox(false);
  }
  const sortNullishValues = (arr = []) => {
    const assignValue = (val :any) => {
       if (val === null) {
          return Infinity;
       } else {
          return val;
       };
    };
    // sort((a:any, b:any) => (a.priority ? a.priority : 1 > b.priority ? b.priority : 1) ? 1 : -1).
    const sorter = (a:any, b:any) => {
       return assignValue(a.priority ? a.priority : 1) - assignValue(b.priority ? b.priority : 1);
    };
   return [...arr].sort(sorter);
 }
 
  return (
    <><Map centerX={centerX} centerY={centerY} zoom={zoom}>

      {basemapsArray.length > 0 && basemapsArray.map((basemap: any) => basemap.visible &&
        <Tile
          layername={basemap.layername}
          key={basemap.url} url={basemap.url}
          visible={basemap.visible} />
      )}

      {services && services.length > 0 && project2.length > 0 && project2.map((project: any) => {
        return project.layer_groups.map((layerGroup: any) => {
          const layers_: any = [];
          const layerNames_: any = [];
          sortNullishValues(layerGroup.layers).map((layer: any) => {
            // console.log("services.find((service: any) => service.id === layer.service_id)?.type :",
            // services.find((service: any) => service.id === layer.service_id)?.type);
            
            if (layer.visible && services.find((service: any) => service.id === layer.service_id)?.type === 'VECTOR') {
              layers_.push(layer);
              layerNames_.push(layer.name);
            }
          });

          if (layers_.length) {

            return <WmsLayer
              key={layerGroup.id}
              serviceId={services.find((service: any) => service.id === layers_[0].service_id)}
              tag={services.find((service: any) => service.id === layers_[0].service_id)?.type}
              priority={layerGroup.priority}
              url={`${services.find((service: any) => service.id === layers_[0].service_id)?.url}` + '/wms'}
              visibleDomains={visibleDomains}
              layername={layerNames_}
            >
            </WmsLayer>;
          }

        });
      })}

      {services && services.length > 0 && project2.length > 0 && project2.map((project: any) => {
        return project.layer_groups.map((layerGroup: any) => {
          const layers_: any = [];
          const layerNames_: any = [];
          layerGroup.layers.map((layer: any) => {
            // console.log("services.find((service: any) => service.id === layer.service_id)?.type :",
            // services.find((service: any) => service.id === layer.service_id)?.type);
            
            if (layer.visible && services.find((service: any) => service.id === layer.service_id)?.type !== 'VECTOR'
            && services.find((service: any) => service.id === layer.service_id)?.type !== 'EXTERNAL_LAYER') {
              layers_.push(layer);
              layerNames_.push(layer.name);
            }
          });

          if (layers_.length) {

            return <WmsLayer
              key={layerGroup.id}
              serviceId={services.find((service: any) => service.id === layers_[0].service_id)}
              tag={services.find((service: any) => service.id === layers_[0].service_id)?.type}
              priority={layerGroup.priority}
              url={`${services.find((service: any) => service.id === layers_[0].service_id)?.url}` + '/wms'}
              visibleDomains={visibleDomains}
              layername={layerNames_}
            >
            </WmsLayer>;
          }

        });
      })}



      {services && services.length > 0 && project2.length > 0 && project2.map((project: any) => {
        return project.layer_groups.map((layerGroup: any) => {

          return layerGroup.layers.map((layer: any) => {

            if (layer.visible) {
              if (services.find((service: any) => service.id === layer.service_id)?.type === 'EXTERNAL_LAYER') {
                return <OwsLayer
                  key={layer.id}
                  serviceId={layerGroup.id}
                  tag={services.find((service: any) => service.id === layer.service_id)?.type}
                  priority={layerGroup.priority}
                  url={`${services.find((service: any) => service.id === layer.service_id)?.url}`}
                  visibleDomains={visibleDomains}
                  layername={[layer.name]}
                >
                </OwsLayer>;
              }
            }
          });
        });
      })}
      { cursorVisible && <Cursor/>}
      <Draw />
      <PanoLook />
      {isMultiOpen && <><PanoLook2 /><PanoLook3 /></>}
      {(selectedFeatures.length !== 0
        || vertexFeature.length !== 0)
        && <SelectedFeature />}
      {(!selectedTableRow && (buttonState === ButtonState.MOVE || buttonState === ButtonState.INFO || buttonState === ButtonState.EDIT || buttonState === ButtonState.VERTEX || buttonState === ButtonState.DELETE || buttonState === ButtonState.SELECTION)) && <WmsInfo callback={onWmsSelected} tag='VECTOR' />}
      {((selectedLayer.name && selectedFeature.length === 1 && selectedLayer.name === selectedFeature[0]?.id?.split('.')[0]) && (buttonState === ButtonState.VERTEX || buttonState === ButtonState.EDIT) && (!panoramaEditStatus)) && <DigiGeomEdit />}
      {/* {console.log("sssssssssss",selectedFeature.length)} */}
      {(!panoramaEditStatus && buttonState === ButtonState.DELETE && (selectedFeature.length >= 1)) && <DigiGeomDelete />}
      <OfflineLayer />
      <Digitization />
      {(drawState.drawStateType === DrawStateType.POINT
        || drawState.drawStateType === DrawStateType.LINESTRING
        || drawState.drawStateType === DrawStateType.POLYGON
        || buttonState === ButtonState.ADD
        || buttonState === ButtonState.VERTEX
        /**
         * feature MOVE snap olmalı
         *
         */
      )
        /* && services.map((ss:any) => {
                if (ss.type === "VECTOR") {
                    return ss.layers.map((layer:any) => {
                        return <WfsLayer
                            key={layer.id}
                            layername={layer.name}
                            visible={layer.visible}
                            url_workspace={ss.url.substring(ss.url.lastIndexOf("/")+1, ss.url.length) }
                        >
                        </WfsLayer>
                    })
                }
            })*/
        && services.map((service: any) => {
          if (service.type === "VECTOR") {
            return projects.map((project: any) => {
              return project.layer_groups.map((layerGroup: any) => {
                return layerGroup.layers.map((layer: any) => {
                  if (layer.service_id === service.id && layer.visible /*&& layer.id === selectedLayer.id*/) {
                    return <WfsLayer
                      key={layer.id}
                      layername={layer.name}
                      visible={layer.visible /*true*/}
                      url_workspace={service.url.substring(service.url.lastIndexOf("/") + 1, service.url.length)}
                    >
                    </WfsLayer>;
                  }
                });
              });
            });
          }
        })}

{(drawState.drawStateType === DrawStateType.POINT
        || drawState.drawStateType === DrawStateType.LINESTRING
        || drawState.drawStateType === DrawStateType.POLYGON
        || buttonState === ButtonState.ADD
        || buttonState === ButtonState.VERTEX
        || buttonState === ButtonState.SELECTION
        || buttonState === ButtonState.DELETE
        || buttonState === ButtonState.EDIT
        || buttonState === ButtonState.MOVE





      )

        && services.map((service: any) => {
          if (service.type === "VECTOR") {
            return projects.map((project: any) => {
              return project.layer_groups.map((layerGroup: any) => {
                return layerGroup.layers.map((layer: any) => {
                  if (layer.service_id === service.id && layer.visible && layer.id === selectedLayer.id) {
                    return <BoxSelection
                      key={layer.id}
                      layername={layer.name}
                      visible={layer.visible /*true*/}
                      url_workspace={service.url.substring(service.url.lastIndexOf("/") + 1, service.url.length)}
                    >
                    </BoxSelection>;
                  }
                });
              });
            });
          }
        })}



      <Dialog
        className="ListBox"
        style={{ position: "absolute", top: pixel[1] + 50, left: pixel[0] + 50 }}
        header="Veri Listesi"
        visible={visibleListBox}
        modal={false}
        onHide={() => { setVisibleListBox(false); } }
      >
        <ListBox
          options={featureList}
          onChange={(e) => {
            selectFeature(e.value);
          } }
          optionLabel="name"
          style={{ width: "15rem" }} />
      </Dialog>

    </Map> {tableVisibility ? <div style={{ position: "absolute", bottom: "10px" }}><Basemap /></div> : <div style={{ position: "absolute", bottom: "100px" }}><Basemap /></div>}</>
  )
}



