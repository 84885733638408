import React, { useState, useRef, useEffect } from 'react';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog'; // Import Dialog component
import 'primereact/resources/primereact.min.css'; // PrimeReact core
import 'primeicons/primeicons.css'; // PrimeIcons
import './PasswordChangeForm.scss';
import { LoginRestApi } from '../../util/restapi/login';
import LoadingScreen from '../../components/shared/loading/LoadingScreen';
import { setShowLoading } from '../../state/features/loadingSlice';
import { useDispatch } from 'react-redux';
import { setCustomize, setToken } from '../../state/features/customizeSlice';
import { CustomizeRestApi } from '../../util/restapi/customize';
import { useTranslation } from 'react-i18next';
import { ValidateRestApi } from '../../util/restapi/validate';
import { setCurrentLanguage } from '../../state/features/languageSlice';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';


interface FormData {
    currentPassword: string;
    newPassword: string ;
    confirmPassword?: string;
  }

  interface FormErrors {
    currentPassword?: string;
    newPassword?: string | string[] | undefined;
    confirmPassword?: string;
}
  


  interface Status {
    type: 'loading' | 'success' | 'error';
    message: string;
}  


interface PasswordChangeFormProps {
    visible: boolean;
    onHide: () => void;
}



const PasswordChangeForm = ({ visible, onHide }: PasswordChangeFormProps) => {

  const [formData, setFormData] = useState<FormData>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''

  });

  const dispatch = useDispatch();
  const [errors, setErrors] = useState<FormErrors>({});
  const [status, setStatus] = useState<Status | null>(null);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const toast = useRef<any>(null);
  const navigate = useNavigate();



  // Password validation rules
  const passwordRules = [
    { test: (password: string) => password?.length >= 8 || false, message: (t("MESSAGE.Must be at least 8 characters"))},
  ];


  const getCustomize = async () => {
    try {

      dispatch(setShowLoading(true))

      const response = await CustomizeRestApi.customize()
      dispatch(setCustomize(response.data.customize));
    } catch (error) {
        dispatch(setShowLoading(false))


    }  finally {
        dispatch(setShowLoading(false));  

    }
  }

  const validateForm = () => {

    const newErrors: FormErrors = {};
    
    if (!formData.currentPassword?.trim()) {
      newErrors.currentPassword = (t("MESSAGE.Current Passoword is required"));
    }

    const failedRules = passwordRules.filter(rule => !rule.test(formData.newPassword));
    
    if (failedRules.length > 0) {
      newErrors.newPassword = failedRules.map(rule => rule.message);
    }

    if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    if (formData.newPassword === formData.currentPassword) {
        newErrors.newPassword = [
            ...(Array.isArray(newErrors.newPassword) ? newErrors.newPassword : []),
            (t("MESSAGE.New password must be different from current password"))
        ];
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;

  };

  const licenseValidate = async () => {
    try {
      await ValidateRestApi.validate()
    } catch (error) {
    }
  }


  useEffect(() => {
    // if(logoff){
    //     window.location.reload();
    // } 
    getCustomize()
    licenseValidate()
    localStorage.setItem('activeLanguage', +(window as any).innerWidth <= 640 ? 'eng' : localStorage.getItem('activeLanguage') || 'tr');

    if(+(window as any).innerWidth <= 640){
        i18n.changeLanguage("eng");
        dispatch(setCurrentLanguage("eng"))
    }

    // dispatch(setLogOff(false)); 
  }, [])




  const handleSubmit = async (e: React.FormEvent) => {

    e.preventDefault();
    setStatus(null);

    if (!validateForm()) {
      return;
    }
    dispatch(setShowLoading(true));

    setTimeout( async () => {

    try {
      setStatus({ type: 'loading', message: 'Changing password...' });
      
      // Call the API

        await LoginRestApi.changePassword({
          oldPassword: formData.currentPassword,
          newPassword: formData.newPassword
        });
      
     setStatus({
        type: 'success',
        message: (t("MESSAGE.Password changed successfully! Please log in again."))
      }); 

      toast.current?.show({ severity: 'sucess', detail: t("MESSAGE.Your password has been successfully reset"), life: 7000 });

      
      setFormData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });

      dispatch(setShowLoading(false));


      // Close dialog after success
      setTimeout(()=>{
        onHide();
      },1800)

      setTimeout(() => {
        navigate('/login', { replace: true }); // Use replace option
    }, 2000);

    } catch (error) {
      toast.current?.show({ severity: 'error', detail: t("MESSAGE.Data not found"), life: 5000 });
      setStatus({
        type: 'error',
        message: (t("Old Password is wrong. Please try again."))
      });
      dispatch(setShowLoading(false));

    }},2700)

  };

  const handleChange = (value: string, field: keyof FormData) => {
    setFormData(prev => ({
        ...prev,
        [field]: value
    }));
    
    if (errors[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: undefined
      }));
    }
  };

  const header = <h2 className="text-2xl font-bold m-0">{t("BUTTON.Change Password")}</h2>;
  
  const footer = (
    <div className="flex justify-end">
      <Button 
        label={status?.type === 'loading' ? 'Changing Password...' : 'Change Password'}
        icon="pi pi-check" 
        disabled={status?.type === 'loading'}
        onClick={handleSubmit}
      />
    </div>
  );

  return (

   
    <div className="flex justify-center align-items-center">
      
      <Toast ref={toast} position='bottom-center' style={{ zIndex: '9 !important' }} />

      <Dialog  className="password-change-dialog" header={header}  visible={visible} onHide={onHide}  >
        
        <form onSubmit={e => e.preventDefault()} className="flex flex-column gap-4">

        <div className='DialogText'>

           <h1>{t("TEXT.Set New Password")}</h1>
           <p>{t("TEXT.Your new Password must be different from previous used passwords.")}</p>

       </div>

          <div className="flex flex-column gap-2">
            <Password
              value={formData.currentPassword}
              onChange={(e) => handleChange(e.target.value, 'currentPassword')}
              placeholder={t("BUTTON.Current Password")}
              toggleMask
              className={errors.currentPassword ? 'p-invalid' : ''}
              feedback={false}
            />
            {errors.currentPassword && (
              <small className="p-error">{errors.currentPassword}</small>
            )}
          </div>

          <div className="flex flex-column gap-2">
            <Password
              value={formData.newPassword}
              onChange={(e) => handleChange(e.target.value, 'newPassword')}
              placeholder={t("BUTTON.New Password")}
              toggleMask
              className={errors.newPassword ? 'p-invalid' : ''}
              promptLabel="Choose a password"
              weakLabel="Too simple"
              mediumLabel="Average complexity"
              strongLabel="Complex password"
            />
            {errors.newPassword && (
              <ul className="p-error list-none p-0 m-0">
                {Array.isArray(errors.newPassword) ? 
                  errors.newPassword.map((error, index) => (
                    <li key={index}>{error}</li>
                  )) : 
                  <li>{errors.newPassword}</li>
                }
              </ul>
            )}
          </div>

          <div className="flex flex-column gap-2">
            <Password
              value={formData.confirmPassword}
              onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
              placeholder={t("BUTTON.Confirm New Password")}
              toggleMask
              className={errors.confirmPassword ? 'p-invalid' : ''}
              feedback={false}
            />
            {errors.confirmPassword && (
              <small className="p-error">{errors.confirmPassword}</small>
            )}
          </div>

          <div className="flex justify-end">

             <Button 
             label={status?.type === 'loading' ? 'Changing Password...' : (t('BUTTON.Submit'))}
             disabled={status?.type === 'loading'}
             onClick={handleSubmit}
             />

          </div>

          {status && (
            <Message 
              severity={status.type === 'success' ? 'success' : status.type === 'error' ? 'error' : 'info'}
              text={status.message}
              className="w-full"
            />
          )}

          {status?.type === 'loading' && (
            <div className="flex justify-center">
              <ProgressSpinner style={{width: '50px', height: '50px'}} />
            </div>
          )}
        </form>
      </Dialog>
    </div>
  );
};

export default PasswordChangeForm;