import './Information.scss'
import { Dialog } from 'primereact/dialog';
import { useAppSelector } from '../../../state/hooks';
import { useDispatch } from 'react-redux';
import { setSelectedFeature } from '../../../state/features/selectedFeatureSlice';
import { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { AttachmentRestApi } from '../../../util/restapi/attachment';
import { Image } from 'primereact/image';
import { useTranslation } from 'react-i18next';
import saveAs from 'file-saver';
import { Layer, Project } from '../../../util/model/project';
import { RootState } from '../../../state/store';
import { ButtonState } from '../button-state/ButtonState';
import axios from 'axios';
import { setButtonState, setShowInfoFeature, setShowInfoWindow } from '../../../state/features/buttonStateSlice';
import React from 'react';
import { Calendar } from 'primereact/calendar';
import { ColorPicker } from 'primereact/colorpicker';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';

export const Information = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const sidebarVisibility = useAppSelector(state => state.buttonState.sidebarButton);
    const buttonState = useAppSelector(state => state.buttonState.buttonState)
    // const selectFeature: any = useAppSelector(state => state.selectedFeature.feature);
    const selectFeature: any = useAppSelector(state => state.buttonState.showInfoFeature)
    const showInfoWindow = useAppSelector(state => state.buttonState.showInfoWindow)
    const selectedLayerInfo: any = useAppSelector(state => state.info.selectedLayerInfo);
    const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects);
    const selectedFeature = selectFeature[0]
    const { t } = useTranslation();
    const featureArray: any = [];
    const dispatch = useDispatch();
    const [featureArr, setFeatureArray] = useState<any>([]);
    const [attachmentArr, setAttachmentArr] = useState<any>({});
    const [token, setToken] = useState<any>(localStorage.getItem('token'))
    const [allAttachments, setAllAttachments] = useState<any>([]);
    const [feature, setFeature] = useState<any>({});
    const [attachmentArray, setAttachmentArray] = useState<any>({});
    const [layer, setLayer] = useState<any>({})
    const [domaindescriptionObj, setDomainDescriptionobj] = useState<any>()
    const [user, setUser] = useState<string>("")
    const [touchUser, setTouchUser] = useState<string>("")
    const [allUsers, setAllUsers] = useState<any>([]);
    const { REACT_APP_BASE_URL } = process.env
    let [selectedFeatureProperties, setSelectedFeatureProperties] = useState<any>(null)
    const cal = useRef(null);




    const handleFullScreen = () => {
        if (videoRef.current) {
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            }
        };
    }
    const onHide = () => {
        dispatch(setShowInfoWindow(false))
        dispatch(setShowInfoFeature(null))
        dispatch(setSelectedFeature([]))
        // dispatch(setButtonState(ButtonState.NONE))
    }

    useEffect(() => {
        if (selectedFeature) {
            setAllAttachments([])
            setAttachmentArr([])
            setFeatureArray([])
            let feature = selectedFeature.properties;
            //get attachment
            const id = (selectedFeature.id).split('.')[1];
            const layerName = (selectedFeature.id).split('.')[0];
            projects.map((project: Project) => {
                project.layer_groups.map((layerGroup: any) => {
                    layerGroup.layers.map((layer: Layer) => {
                        if (layer.name === layerName) {
                            setLayer(layer)
                            AttachmentRestApi.getAttachmentOfFeature(layer.id, +id).then((res: any) => {
                                const response = res.data
                                if (response.length > 0) {
                                    const pattern = /[\/.](gif|jpg|jpeg|tiff|png|svg)$/;
                                    response.forEach((item: any) => {
                                        const isValidImage = pattern.test(item.filename.toLowerCase());
                                        const url2 = generateLink(item)
                                        item.imageUrl = isValidImage ? url2 : null;
                                        item.downloadUrl = url2 + `&download=true`;
                                        item.isValidImage = isValidImage;
                                        setAllAttachments(response)
                                        setFeature(feature)
                                    });
                                }
                                else {
                                    setFeature(feature)
                                }
                            })
                        }
                    })
                })
            })


        }
    }, [selectedFeature, selectedLayerInfo])

    useEffect(() => {
        if (!Object.keys(feature).length) {
            return
        }
        if (layer.id) {
            const selectedLayerName = selectedFeature.id.split('.')[0];

            let clonedFeature = Object.assign({}, feature)
            // layerGroups.map((layerGroup: any) => {
            //     const selectedLayer = layerGroup.layers.find((layer: any) => layer.name === selectedLayerName)
            //     setLayer(selectedLayer)

            if (layer) {
                const domainfeature = layer.feature?.filter((field: any) => field.type === 'Domain')

                const featureOfLayer = domainfeature?.filter((domainField: any) => domainField.layer_id === layer.id)
                if (featureOfLayer && featureOfLayer?.domain) {
                    for (const domain of featureOfLayer.domain) {

                        // for (const domain of domains.domain) {
                        if (domain.id === feature[domain.name]) {
                            clonedFeature = { ...clonedFeature, [domain.name]: domain.desc }

                        }
                        // }
                    }
                }
            }
            // })
            //get alias for labels
            if (layer && layer.fields && layer.fields.length > 0) {
                for (const field of layer.fields) {
                    for (const [key, value] of Object.entries(clonedFeature)) {
                        if (key !== 'bbox') {
                            if (field.name === key) {
                                featureArray.push({ label: field.alias, value: value, priority: field.priority })
                            }
                        }
                    }
                    if (field.type === 'Attachment') {
                        setAttachmentArray([{ label: field.alias, value: allAttachments }])
                    }
                }
            }

            //sort
            // const sortedFeatureArray = featureArray;
            const sortedFeatureArray = featureArray.sort((a: any, b: any) => a.priority - b.priority)
            // const sortedFeatureArray = featureArray.sort((a: any, b: any) => {
            //     return (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0;
            // });


            if (sortedFeatureArray.length) {
                setFeatureArray(sortedFeatureArray)
                const createdUser = sortedFeatureArray.find((el: any) => el.label === 'Created By')

                if (createdUser && createdUser.value) {
                    axios.get(process.env.REACT_APP_BASE_URL + `rest/v1/user/${createdUser.value}?token=${token}`)
                        .then((response: any) => {
                            setUser(response.data.user.username)
                        })
                } else {
                    setUser("")
                }

                const touchUser = sortedFeatureArray.find((el: any) => el.label === 'touch By');
                if (touchUser && touchUser.value) {
                    axios.get(process.env.REACT_APP_BASE_URL + `rest/v1/user/${touchUser.value}?token=${token}`)
                        .then((response: any) => {
                            setTouchUser(response.data.user.username)
                        })
                } else {
                    setTouchUser("")
                }
            }

        }
    }, [feature, layer])
    const getUsers = async () => {
        const sortedFeatureArray = featureArray.sort((a: any, b: any) => a.priority - b.priority)
        const touchUser = sortedFeatureArray.find((el: any) => el.label === 'touch By');
        if (touchUser && touchUser.value) {
            const usersResponse = await axios.get(REACT_APP_BASE_URL + `rest/v1/user/${touchUser.value}?token=${token}`)
            setAllUsers([usersResponse.data.user])
        }
    }


    useEffect(() => {
        if (attachmentArray.length > 0 && attachmentArray[0].value.length > 0) {
            attachmentArray[0].value.sort((a: any, b: any) => {
                return (a.isValidImage === b.isValidImage) ? 0 : a.isValidImage ? -1 : 1;
            });
            setAttachmentArr(attachmentArray)
        }
    }, [attachmentArray])

    const generateLink = (attachment: any) => {
        const { REACT_APP_BASE_URL } = process.env
        return REACT_APP_BASE_URL + `rest/v1/attachment/${attachment.id}?token=${token}`
    }

    const downloadFile = (item: any) => {
        saveAs(item.downloadUrl);
    }

    useEffect(() => {
        if (selectedFeature) {

            setSelectedFeatureProperties(selectedFeature?.properties);
        }
        const filteredFields = layer.fields?.filter((field: any) => field.name !== 'bbox' && field.name != 'geom');
        if (filteredFields) {
            setDomainDescriptionobj(filteredFields.sort((a: any, b: any) => a.priority - b.priority))
        }
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layer.fields, selectedFeature])
    
    return (
        <>
            {domaindescriptionObj?.length > 0 && <Dialog header={layer.alias + ' ' + selectedFeature?.id?.split('.')[1]} visible={showInfoWindow} position='top-left' modal={false} className={sidebarVisibility ? 'dialog-box-sidebar-open' : 'dialog-box'} onHide={onHide}
                draggable={true} resizable={true}>
                {domaindescriptionObj?.map((field: any, index: any) => <React.Fragment key={field.id}>

                    {field.type !== 'Attachment' &&
                    <><div className='label'>{field.alias}:</div>
                    <div key={feature.id} className='line'>

                            {(field.type === 'Telephone') &&
                                <>
                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} maxLength={250} className='input-style' style={{ width: "58%" }} /* style={{ width: "66%" }} */ />
                                </>}
                            {field.type === 'Text' &&
                                <>

                            <InputTextarea readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} maxLength={250} className='input-style'  rows={1} cols={1} autoResize    /></>}
                            {field.type === 'Email' &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} maxLength={255} value={field.inputValue} className='input-style' />
                                </>}
                            {field.type === 'Height' &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} maxLength={255} value={field.inputValue} required={!field.nullable} className='input-style' />
                                </>}
                            {field.type === 'CreatedBy' && selectedFeatureProperties &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={user} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}
                            {field.type === 'TouchBy' && selectedFeatureProperties &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={touchUser} maxLength={250} className='input-style' />
                                </>}
                            {field.type === 'CreatedDate' && selectedFeatureProperties &&
                                <>
                                    <Calendar
                                        showOnFocus={false}
                                        locale="tr"
                                        /* className={`p-calendar input-style`}  */
                                        name={field.name}
                                        value={new Date(selectedFeatureProperties[field.name])}
                                        dateFormat="dd-mm-yy"
                                        readOnlyInput
                                        placeholder='Başlangıç Tarihi Seçiniz'
                                        ref={cal}
                                        id={field.alias}
                                        required={!field.nullable ? !selectedFeatureProperties[field.name] : false}
                                       /*  style={{ width: "58%" }} */
                                    >
                                    </Calendar>

                                </>}
                            {field.type === 'TouchDate' && selectedFeatureProperties &&
                                <>

                                    <Calendar
                                        locale="tr"
                                       /*  className={`.p-calendar .p-inputtext input-style`} */
                                        name={field.name}
                                        value={new Date(selectedFeatureProperties[field.name])}
                                        showOnFocus={false}
                                        dateFormat="dd-mm-yy"
                                        readOnlyInput
                                        placeholder='Başlangıç Tarihi Seçiniz'
                                        ref={cal}
                                        id={field.alias}
                                        required={!field.nullable ? !selectedFeatureProperties[field.name] : false}
                                    >
                                    </Calendar>

                                </>}
                            {field.type === 'Id' && selectedFeatureProperties &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} maxLength={250} className='input-style' />
                                </>}
                            {(field.type === 'Float') &&
                                <>

                                    <InputNumber readOnly name={field.name} inputId='minmaxfraction' mode='decimal' minFractionDigits={1} maxFractionDigits={6} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} className='input-style' style={{ display: "contents" }} />
                                </>}
                            {(field.type === 'Integer') &&
                                <>

                                    <InputNumber readOnly name={field.name} inputId='int' mode='decimal' id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} className='input-style' />
                                </>}
                            {field.type === 'Domain' &&
                                <>
                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={field.domain?.find((item: any) => item?.id === selectedFeatureProperties[field.name])?.desc} maxLength={250} className='input-style' />

                                </>}

                            {field && (field.type === 'Color') &&
                                <>
                                    <div style={{ display: 'flex', maxWidth: '160px !important' }}>
                                        <ColorPicker format="hex" name={field.name} inputId='color' id={field.alias}
                                            value={selectedFeatureProperties[field.name] ? selectedFeatureProperties[field.name] : '-'}
                                            required={!field.nullable ? !selectedFeatureProperties[field.name] : false} style={{height: "35px !important",paddingRight:"5px" }} /* className='input-style' */ />

                                        <InputText
                                            readOnly
                                            name="colorField" //{field.name} 
                                            key="999color" //{field.id} 
                                            id="colorFieldId" //{field.alias} 
                                            value={selectedFeatureProperties[field.name] ? selectedFeatureProperties[field.name] : '-'}
                                            maxLength={6} />
                                    </div>
                                </>}

                            {field && (field.type === 'Date') &&
                                <>


                                    <Calendar
                                        showOnFocus={false}
                                        locale="tr"
                                     /*    className={`p-calendar p-inputtext input-style`} */
                                        name={field.name}
                                        value={new Date(selectedFeatureProperties[field.name])}
                                        dateFormat="dd-mm-yy"
                                        readOnlyInput
                                        placeholder='Başlangıç Tarihi Seçiniz'
                                        ref={cal}
                                        id={field.alias}
                                        required={!field.nullable ? !selectedFeatureProperties[field.name] : false}
                                    >
                                    </Calendar>

                                </>}
                            {(field.type === 'Length') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Start_Point') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Start_Point(X)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Start_Point(Y)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Start_Point(Z)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Middle_Point') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Middle_Point(X)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Middle_Point(Y)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Middle_Point(Z)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'End_Point') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'End_Point(X)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'End_Point(Y)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'End_Point(Z)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Area') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Perimeter') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties.perimeter || ''} required={!field.nullable} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Location') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Location(X)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                            {(field.type === 'Location(Y)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}
                            {(field.type === 'Location(Z)') &&
                                <>

                                    <InputText readOnly name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}

                        </div></>
                    }
                </React.Fragment>
                )}
                {allAttachments && allAttachments.length > 0 && attachmentArr.length > 0 &&
                    <div className="attachment-line">
                        {
                            attachmentArr.map((file: any) =>
                                <div key={file.label} className='file-group'>
                                    <p className='label'>{file.label}:</p>
                                    <div className='file-box'>
                                        {(file.value).length > 0 && file.value.map((val: any) =>
                                            (val.file_type).includes('image') ?
                                                <div key={val.id} className='image-container'>
                                                    <Image
                                                        src={val.imageUrl}
                                                        alt={val.file_type}
                                                        preview
                                                    />
                                                    <div key={val.id} className='file-name-container'>
                                                        <div className='name file-name'>
                                                            {val.name}
                                                        </div>
                                                    </div>
                                                    <div className='button-container'>
                                                        <a className='anim anim-from-left' onClick={() => downloadFile(val)} download>
                                                            {t('BUTTON.Download')}
                                                        </a>
                                                    </div>
                                                </div>
                                                :
                                                <div key={val.id + 's'} className='file-container'>
                                                    {/*             <Image
                src='customize-icons/file.svg'
                alt={val.file_type}
                className='file-icon'
            /> */}
                                                    <div className='file-name-container'>
                                                        <div className='file-name'>
                                                            <video
                                                                ref={videoRef}
                                                                controls
                                                                muted
                                                                style={{ zIndex: '0', width: '100%', height: 'auto' }}
                                                            >
                                                                <source src={val.downloadUrl} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        </div>

                                                        <div className='button-container'>
                                                            <button onClick={handleFullScreen} className='full-screen-button '>
                                                                {t("BUTTON.Full Screen")}
                                                            </button>
                                                            <button className='download-btn pi pi-download'>
                                                                <a className='anim  anim-from-left ' style={{ paddingLeft: "5px" }} onClick={() => downloadFile(val)} download>
                                                                    {t('BUTTON.Download')}
                                                                </a>
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>


                                        )}
                                    </div>
                                </div>
                            )
                        }
                    </div>}
            </Dialog>}
        </>
    )
}
