import './Export.scss';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
/* import { Project } from '../../util/model/project';
import { RootState } from '../../state/store'; */
import { setButtonState, setExportButton } from '../../state/features/buttonStateSlice';
import { ButtonState } from '../shared/button-state/ButtonState';
import {  setExportedEpsg, setShowExport } from '../../state/features/exportSlice';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import CheckBox from '../shared/custom/CheckBox';
import { Toast } from 'primereact/toast';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Draw, {
  DrawEvent,
  createBox,
} from 'ol/interaction/Draw';
import { Permission } from '../../util/restapi/permission';


export enum ExportType {
  GeoJson = 'application/json',
  Kml = 'application/vnd.google-earth.kml+xml',
  ShapeFile = 'SHAPE-ZIP',
  Gml = 'application/gml+xml; version=3.2',
  Dxf = 'DXF',
}
/* type ProjectionType = 'EPSG:3857' | 'EPSG:4326';
 */// type ExportValueType = 'AllData' | 'MapBBox' | 'DrawBBox';
/* type ExportFormatValueType = 'GeoJson' | 'Kml' | 'ShapeFile' | 'Gml' | 'Dxf';
 */export const Export = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
 /*  const [error, setError] = useState<number>(0); */
  const [exportEpsg, setExportEpsg] = useState<string>('');
  const [format, setFormat] = useState<string>('GeoJson');
  const [coverage, setCoverage] = useState<string>('AllData');
  const { epsgList } = require('./epsgList')
  const [epsgDown, setEpsgDown] = useState<string>('');
  const toast = useRef<any>();
  const { REACT_APP_BASE_URL } = process.env
  const map = (window as any).olMap

  const services: any = useAppSelector(state => state.layer.services);

  const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer)
  /* const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects); */
  const layerAlias = useAppSelector(state => state.export.layerAlias)
 /*  const exportedEpsg = useAppSelector(state => state.export.exportedEpsg) */
  const workspaceUrl = useAppSelector(state => state.export.url)
/*   const workspace = useAppSelector(state => state.export.workspace)
 */  const [downloadDesc, setDownloadDesc] = useState<boolean>(false)
  const [downloadAttachmentRelation, setDownloadAttachmentRelation] = useState<boolean>(false)
  /* const [group_alias, setGroup_alias] = useState<string>(''); */
  const [drawedBBox, setDrawedBBox] = useState<any>(null);
/*   const [newExtent, setNewExtent] = useState<any>(null); */
  const token = localStorage.getItem('token')//this.authService.getToken();

  const [drawing, setDrawing] = useState<boolean>(true)
/*   const [drawbbox, setDrawbbox] = useState<any>()
  const [removeFeature, setRemoveFeature] = useState<any>() */
  const [draw, setDraw] = useState<any>()

  enum DrawToolEventType {
    DRAWSTART,
    DRAWEND,
    DRAWABORT
  }
/*   interface DrawToolEvent {
    feature: Feature;
    type: DrawToolEventType;
  } */
  const onDrawEvent = (evt: any, type: DrawToolEventType): void => {
    if (type === DrawToolEventType.DRAWSTART) {
      const layer = map.getLayers().getArray().find(
        (el: any) => el.getProperties().alias === 'boxDraw'
      )
      layer.getSource().clear();


      //  map.getSource().removeFeature(feature);
    } else if (type === DrawToolEventType.DRAWEND) {
      /*setDrawbbox*/
      setDrawedBBox(evt.feature.getGeometry().getExtent())
      setDrawing(true)
      // setRemoveFeature(evt.feature)

    } else {
      setDrawing(false)
    }
  }



  useEffect(() => {

    if (drawedBBox) {
      getBBox('DrawBBox')
    }
  }, [drawedBBox]);
  useEffect(() => {
    if (coverage === 'DrawBBox') {
      setDrawing(false)
      // setDraw(null)      
      const layer = map.getLayers().getArray().find(
        (el: any) => el.getProperties().alias === 'boxDraw'
      )
      const source: any = layer.getSource();
      const draw = new Draw({
        source,
        type: 'Circle',//'Point',
        geometryFunction: createBox(),
        stopClick: true,
      });

      draw.on('drawend', (e: DrawEvent) => onDrawEvent(e, DrawToolEventType.DRAWEND));
      draw.on('drawabort', (e: DrawEvent) => onDrawEvent(e, DrawToolEventType.DRAWABORT));
      draw.on('drawstart', (e: DrawEvent) => onDrawEvent(e, DrawToolEventType.DRAWSTART));
      setDraw(draw)
      map.addInteraction(draw);
    } else {
      setDrawing(true)
      const layer = map.getLayers().getArray().find(
        (el: any) => el.getProperties().alias === 'boxDraw'
      )
      if (layer) {
        layer.getSource().clear();
      }
      map.removeInteraction(draw);
    }

  }, [coverage]);

  const hide = async () => {
    dispatch(setShowExport(false))
    dispatch(setExportButton(false))
    dispatch(setButtonState(ButtonState.NONE))

    map.removeInteraction(draw);
    const layer = map.getLayers().getArray().find(
      (el: any) => el.getProperties().alias === 'boxDraw'
    )
    if (layer) {
      layer.getSource().clear();
    }
    dispatch(setShowExport(false));
  };
  const fetchData = async () => {
    const url_ = `${workspaceUrl}/ows?service=wfs&version=1.0.0&request=getCapabilities`;
    /**
     * "https://dev-gis.ankageo.com/rest/v1/geoserver/dev-gis/ows?service=wfs&version=1.0.0&request=getCapabilities&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN1cGVyYWRtaW4iLCJpZCI6MjAxMSwiaWF0IjoxNjgzMzA1NTIyLCJleHAiOjE2ODMzNDg3MjJ9.h_LblSPz_WtQdgnpCQ41DhAu49qlz1Zmip1hqigJ-nM"
     */
    return await axios.get(url_, { responseType: 'text' })
  }

  useEffect(() => {
    const layer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 100000000000000,
      properties: {
        alias: "boxDraw"
      }
    });
    map.addLayer(layer);
    return () => { map.removeLayer(layer) }
  }, [])

  useEffect(() => {
    if (Object.keys(selectedLayer).length > 0) {
      const type = services.find((service: any) => service.id === selectedLayer.service_id)?.type
      if (type === 'RASTER') {
        const url = `${REACT_APP_BASE_URL}rest/v1/geoserver/rest/resource/data/${selectedLayer.name}.tif`;
        window.open(url, '_blank');
        hide()
      } else {
        fetchData().then((data: any) => {
          if (data) {
            const XMLParser = require('react-xml-parser');
            const xml = new XMLParser().parseFromString(data.data);
            const children = xml.getElementsByTagName('FeatureTypeList')[0].children.filter((x: any) => x.name === 'FeatureType')
            const find = children.find((el: any) => el.children[0].value.substring(el.children[0].value.lastIndexOf(":") + 1) === selectedLayer.name)
            const epsg = find.children[4].value
            dispatch(setExportedEpsg(find.children[4].value))
            setExportEpsg(epsg)
            // dispatch(setExportCompleted(false));
          }
        })
      }
    }
  }, [selectedLayer])

  const downloadDomains = () => {
    const { ExportToCsv }: any = require('export-to-csv')
    const domainDescObj = selectedLayer.fields.filter((el: any) => el.type === 'Domain')
    if (domainDescObj) {
      for (let i = 0; i < domainDescObj.length; i++) {
        const domaindesc = domainDescObj[i]['domain'];
        const alias_i = domainDescObj[i]['alias'];
        const options = {
          filename: `${layerAlias}_${alias_i}`,
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: false,
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(domaindesc);
      }
    }
  }

  const getBBox = (type: string): any => {
    let bbox;
    switch (type) {
      case 'AllData': break;
      case 'DrawBBox':
        bbox = drawedBBox;
        break;
      case 'MapBBox':
        const extent = map.getView().calculateExtent(map.getSize())// transformExtent(newExtent, 'EPSG:4326', 'EPSG:3857');
        bbox = extent; // this.dataService.map.getView().calculateExtent(this.dataService.map.getSize())
        break;
    }
    return bbox ? bbox.join(',') + ',EPSG:3857' : undefined;
  }
  const onClickExport = async () => {
    if (epsgDown === '') {
      toast.current?.show({ severity: 'error', detail: t(`BUTTON.MustChooseProjectionType`), life: 3000 });
      return;
    }

    let _exportFormat: ExportType;
    let _suffix: string;
    /* let exportFormat: ExportFormatValueType; */
    switch (format) {
      case 'GeoJson':
        _exportFormat = ExportType.GeoJson;
        _suffix = 'json';
        break;
      case 'Dxf':
        _exportFormat = ExportType.Dxf;
        _suffix = 'dxf';
        break;
      case 'Gml':
        _exportFormat = ExportType.Gml;
        _suffix = 'gml';
        break;
      case 'Kml':
        _exportFormat = ExportType.Kml;
        _suffix = 'kml';
        break;
      case 'ShapeFile':
        _exportFormat = ExportType.ShapeFile;
        _suffix = 'zip';
        break;
      default:
        throw new Error('Type is not defined: ' + format);
    }

    const bbox = getBBox(coverage);
    let downloadFileName;

    if (layerAlias === '') {
      toast.current?.show({ severity: 'error', detail: t(`BUTTON.MustChooseLayer`), life: 3000 });
      return;
    } else {
      Permission.getPermisionOfUserOnLayer(selectedLayer.id).then((response) => {
        if (response.data.permission >= 3) {
          downloadFileName = /*this.selectedLayer.alias ||*/ selectedLayer.name.slice(0, -4);
          downloadFileName = downloadFileName.trim();
          downloadFileName = downloadFileName + '.' + _suffix;
          const _bbox = bbox ? '&BBOX=' + bbox : '';
          const _token = token ? '&token=' + token : '';
          const _downloadFileName = downloadFileName ? '&downloadFileName=' + downloadFileName : '';
          const downloadable = true;
          const charset = 'CHARSET:UTF-8';

          if (_exportFormat === ExportType.Gml) {
            const url = `${workspaceUrl}/ows/export?version=1.0.0&service=WFS&request=GetFeature&downloadable=${downloadable}&typeName=` +
              `${selectedLayer.name}${_token}${_downloadFileName}${_bbox}` + `&srsname=EPSG:${epsgDown}&format_options=${charset}`;
            window.open(url, '_blank');
          } else if (_exportFormat === ExportType.ShapeFile) {
            const url = `${workspaceUrl}/ows/export?version=1.0.0&service=WFS&request=GetFeature&downloadable=${downloadable}&typeName=` +
              `${selectedLayer.name}&outputFormat=${_exportFormat}${_token}${_downloadFileName}${_bbox}` + `&srsname=EPSG:${epsgDown}&format_options=${charset}`;
            window.open(url, '_blank');
          } else {
            const url = `${workspaceUrl}/ows/export?version=1.0.0&service=WFS&request=GetFeature&downloadable=${downloadable}&typeName=` +
              `${selectedLayer.name}&outputFormat=${_exportFormat}${_token}${_downloadFileName}${_bbox}` + `&srsname=EPSG:${epsgDown}&format_options=${charset}`;
            window.open(url, '_blank');
          }

          // ServiceRestApi.getServiceById(selectedLayer.service_id).then((service:any)=>{

          // })
          // const layer = services.find((el:any) => el.name === this.workspaceLayer.layer.service_name);
          // const id = layer.layers.find(el => el.name === this.selectedLayerName)['id'];
          const attvarmı: any = selectedLayer.fields.find((field: any) => field.type === 'Attachment')
          if (attvarmı) {
            const url = `${REACT_APP_BASE_URL}rest/v1/attachment/export/${selectedLayer.id}?token=${token}`;

            setTimeout(() => {
              const myWindow = window.open(url, '_blank');
            }, 50);

            if (downloadAttachmentRelation) {
              const url2 = `${REACT_APP_BASE_URL}rest/v1/attachment/exportRelation/${selectedLayer.id}?token=${token}`;

              setTimeout(() => {
                const myWindow2 = window.open(url2, '_blank');
              }, 50);
            }
          }
          // this.dataService.exportPopup = false;
          if (downloadDesc) {
            downloadDomains();
          }
          // this.evtDownloaded.emit();

        }
        else if (response.data.permission < 3) {
          toast.current?.show({ severity: 'error', summary: 'Error Message', detail: t("MESSAGE.YouAreNotAllowed"), life: 3000 });
        }
      }).catch(error => {
        console.error(error);
        
      })
    }
    /* let isNoConflict = 0;
    setError(isNoConflict) */
  };

  const onEpsgChange = (e: any) => {
    setEpsgDown(e.value)
  }
  const downloadDescChange = (value: any) => {
    setDownloadDesc(value)
  }
  const DownloadAttachmentRelationChange = (value: any) => {
    setDownloadAttachmentRelation(value)
  }

  return (
    <div className='Export'>
      <Dialog
        className='export-dialog'
        header={t(`BUTTON.Export`)}
        visible={true}
        modal={false}
        onHide={() => {

          hide()
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div className='pair'>
            {exportEpsg && <div className="info">
              <label className="leftdiv">
                {layerAlias}
              </label>
              <label className="space"> : </label>
              <label>
                {exportEpsg}
              </label>
            </div>
            }
            <div className='item'>
              <label>
                {'GeoJson'}
                <input onChange={(e: any) => setFormat(e.target.value)} checked={format === 'GeoJson'}
                  type="radio" name="screen-type" value="GeoJson"
                  className="form-radio" />
              </label>
              <label>
                {'Kml'}
                <input onChange={(e: any) => setFormat(e.target.value)} checked={format === 'Kml'}
                  type="radio" name="screen-type" value="Kml"
                  className="form-radio" />
              </label>
              <label>
                {'ShapeFile'}
                <input onChange={(e: any) => setFormat(e.target.value)} checked={format === 'ShapeFile'}
                  type="radio" name="screen-type" value="ShapeFile"
                  className="form-radio" />
              </label>
              <label>
                {'Gml'}
                <input onChange={(e: any) => setFormat(e.target.value)} checked={format === 'Gml'}
                  type="radio" name="screen-type" value="Gml"
                  className="form-radio" />
              </label>
              <label>
                {'Dxf'}
                <input onChange={(e: any) => setFormat(e.target.value)} checked={format === 'Dxf'}
                  type="radio" name="screen-type" value="Dxf"
                  className="form-radio" />
              </label>
            </div>
            <div className='item'>
              <label>
                {t(`BUTTON.AllData`)}
                <input onChange={(e: any) => setCoverage(e.target.value)} checked={coverage === 'AllData'}
                  type="radio" name="screen-type2" value='AllData'
                  className="form-radio" />
              </label>
              <label>
                {t(`BUTTON.BBoxMap`)}
                <input onChange={(e: any) => setCoverage(e.target.value)} checked={coverage === 'MapBBox'}
                  type="radio" name="screen-type2" value='MapBBox'
                  className="form-radio" />
              </label>
              <label>
                {t(`BUTTON.BBoxDraw`)}
                <input onChange={(e: any) => setCoverage(e.target.value)} checked={coverage === 'DrawBBox'}
                  type="radio" name="screen-type2" value='DrawBBox'
                  className="form-radio" />
              </label>
            </div>
            <div className='epsg-item'>
              <div className="input-group">
                <fieldset className='field-set'>
                  <legend className="data-type-header">
                    {t('BUTTON.Epsg Code')}
                  </legend>
                  {/* <label htmlFor="epsg">{t('BUTTON.Epsg Code')}</label> */}
                  <Dropdown
                    id="epsg"
                    filter
                    filterBy="value"
                    className="epsg-dropdown"
                    placeholder={t('BUTTON.PleaseChoice')}
                    value={epsgDown}
                    options={epsgList}
                    optionLabel="value"
                    onChange={onEpsgChange}
                  />
                </fieldset>
              </div>
              <div className="butons">
                <div className="checkBoxs">
                  <CheckBox label={t(`BUTTON.DownloadDesc`)} changeChecked={downloadDescChange} />
                  <CheckBox label={t(`BUTTON.DownloadAttachmentRelation`)} changeChecked={DownloadAttachmentRelationChange} />
                </div>
                <Button
                  disabled={!drawing}
                  onClick={() => onClickExport()}
                  label={t(`BUTTON.Export`)}
                  className='download'
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Toast position='bottom-center' style={{ zIndex: '9 !important' }} ref={toast}></Toast>
    </div>
  );
}
