import './Login.scss';
import { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { CustomizeRestApi } from '../../util/restapi/customize';
import { LoginRestApi } from '../../util/restapi/login';
import { useAppSelector } from '../../state/hooks';
import { useDispatch } from 'react-redux';
import { setCustomize, setToken } from '../../state/features/customizeSlice';
import { useNavigate } from "react-router-dom";
import { setLogOff, setLoginStatus, setToastVisibility } from '../../state/features/loginSlice';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { Button } from "primereact/button";
import { ValidateRestApi } from '../../util/restapi/validate';
import { setCurrentLanguage } from '../../state/features/languageSlice';
import { AxiosError } from 'axios';
import { direction } from 'html2canvas/dist/types/css/property-descriptors/direction';
import { Card } from 'primereact/card';
import { reset } from 'ol/transform';
import LoadingScreen from '../../components/shared/loading/LoadingScreen';
import { setShowLoading } from '../../state/features/loadingSlice';



export const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useRef<any>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState<boolean>(false);
  const customize: any = useAppSelector((state: any) => state.customize.value)
  const logoff: any = useAppSelector((state: any) => state.login.logoff)
  const isMobileScreen: number = useAppSelector((state: any) => state.styleControl.isMobileScreen)
  const { i18n } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(120); // 5 minutes in seconds
  const[Timeractive, setTimerActive] = useState(false);
  const[timePassed, setTimePassed] = useState(0); // Track time passed
  const [isPeriodDone , setPeriodDone] = useState(false);


  // forgotten passwords states
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetUsername, setResetUsername] = useState('');

  useEffect(() => {
    // if(logoff){
    //     window.location.reload();
    // } 
    getCustomize()
    licenseValidate()
    localStorage.setItem('activeLanguage', +(window as any).innerWidth <= 640 ? 'eng' : localStorage.getItem('activeLanguage') || 'tr');

    if(+(window as any).innerWidth <= 640){
        i18n.changeLanguage("eng");
        dispatch(setCurrentLanguage("eng"))
    }

    // dispatch(setLogOff(false)); 
  }, [])

  const getCustomize = async () => {
    try {
      const response = await CustomizeRestApi.customize()
      dispatch(setCustomize(response.data.customize));
    } catch (error) {
    }
  }

  const licenseValidate = async () => {
    try {
      await ValidateRestApi.validate()
    } catch (error) {
    }
  }

  useEffect(() => {
    if (isPeriodDone) {

      toast.current?.show({ severity: 'info', detail: (t('MESSAGE.You can send new email again')), life: 5000 });

        
    }
}, [isPeriodDone]);





  // for Timer 

useEffect(() => {
    let timer: any;
    if (Timeractive) {
        timer = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    setTimerActive(false); // This will hide the timer component
                    setPeriodDone(true);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    }
    return () => clearInterval(timer); // Cleanup on unmount
}, [Timeractive]);

// Format time as MM:SS
const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};


  const handleSubmit = (e: any) => {
    e.preventDefault();
    LoginRestApi.login(username, password).then((res: any) => {
      if (res && res.data.token) {
        localStorage.setItem('token', res.data.token);
        dispatch(setToken(res.data.token))
        const savedUsername = localStorage.getItem('username') ? localStorage.getItem('username') : "";
        if(savedUsername !== username){
            localStorage.removeItem('lastCoordinate')
        }
        localStorage.setItem('username', username);
        dispatch(setLoginStatus('LOGIN'));
        dispatch(setToastVisibility(true));
        setTimeout(() => {
          navigate('/');
        }, 100);
      }
      else {
        dispatch(setLoginStatus('LOGOUT'));
      }
    }).catch((err: any) => {
      if (err.response?.status === 401) {
        toast.current?.show({ severity: 'error', detail: (t('LOGIN.Login Fail')), life: 3000 });
        localStorage.removeItem('token')
      } else if (err.response?.status === 400) {
        toast.current?.show({ severity: 'error', detail: (t('LOGIN.Please Fill in Username and Password Field')), life: 3000 });
        localStorage.removeItem('token')
      }
    })
  }

  // ---------------------edit for the scenario
  const sendUrlForForgotPassword = (resetEmail: string) => {
    
    dispatch(setShowLoading(true))
    setTimeout(() => {
    // email will be taken from input of course
    LoginRestApi.sendUrlForForgotPasswordToMail(resetEmail).then(res => {
      if (res.data.success) {
        setTimerActive(true);
        dispatch(setShowLoading(false))
        setTimeLeft(120);
        setPeriodDone(false);
        toast.current?.show({ severity: 'info', detail: (t('MESSAGE.You can reset your password via the url sent to your mail in 30 minutes')), life: 5000 });
      }
    }).catch((error: AxiosError) => {
      console.log("err", { error })
      setTimerActive(false);
      dispatch(setShowLoading(false))
      toast.current?.show({ severity: 'error', detail: error?.response?.data?.message, life: 3000 });

    })
  },1700);
  }
  // ---------------------edit for the scenario

  return (
    <>


        <LoadingScreen/>

        {!showForgotPassword ? (
            <form onSubmit={handleSubmit} className='login'>
                <div className='left'>
                    <div className='line'>
                        <span className='label text-color'>{t('LOGIN.Username')}</span>
                        <InputText value={username} onChange={(e) => setUsername(e.target.value)}     onTouchStart={(e) => (e.target as HTMLInputElement).focus()}
 />
                    </div>
                    <div className='line'>
                        <span className='label text-color'>{t('LOGIN.Password')}</span>
                        <Password type='password' value={password} onChange={(e) => setPassword(e.target.value)} toggleMask />
                    </div>

                  <div className='Forgot-Remember'>

                    <div className='confirm'>

                        <Checkbox inputId="binary" checked={checked} onChange={e => setChecked(e.checked)} />
                        <span className='label text-color'>{t('LOGIN.Remember me')}</span>

                    </div>


                    {/* Forgot Password Button */}
                   <div className='login-forgot-password'>
                       <span className="p-button-info"  style={{  color: 'white' }}  onClick={() => { setShowForgotPassword(true) }} >
                        {t('LOGIN.I forgot my password')}
                        </span>
                    </div>   

                    


                  </div>



                    <div className='login-button'>
                        <Button label={t('LOGIN.Login')} type='submit' />
                    </div>
                </div>
                <div className='right'>
                    <img src={customize.logo} alt="ankageo-logo" width='320px' />
                </div>

                
            </form>

        ) : (
            <>
                <div className='reset-password-container'>

                  <div className='left'>

                    <div className='reset-logo'>
                        <img src={customize.logo} alt="ankageo-logo" width='320px' />
                    </div>

                  </div>


                  <div className='right'> 

                    <h2>{t('LOGIN.Reset Your Password')}</h2>
                    <span>{t('LOGIN.We will send a password reset link to the email address associated with the atlant.ankageo.com account')}</span>

                    <div className='reset-email'>

                        <i className="pi pi-envelope"></i>
                        <InputText placeholder={t('LOGIN.E-mail')} maxLength={40} value={resetEmail}   onTouchStart={(e) => (e.target as HTMLInputElement).focus()}
                        onChange={(e) => setResetEmail(e.target.value)} />

                    </div>

                    


                    <div className='login-forgot-password-button'>
                    
                    {Timeractive &&  

                          <div className="countdown-timer">
                                  <h6>{formatTime(timeLeft)}</h6>
                               
                          </div>

                    }
 
                    {!Timeractive ?
                         <Button 
                           label= {t('LOGIN.Send E-mail')} type='submit' 
                           onClick={() => {
                           sendUrlForForgotPassword(resetEmail); 
                           }} 
                           style = {{ fontSize:'16px', fontWeight:'10px'  ,color:'white'}}
                           />
                    :

                    <Button 
                           label= {t('LOGIN.Send E-mail')} type='submit' 
                           className='p-button-info'
                           onClick={() => {
                            toast.current?.show({ severity: 'error', detail: (t('MESSAGE.Please wait 2 minutes')), life: 5000 });
                           }} 
                           style = {{ fontSize:'16px', fontWeight:'10px'  ,color:'black'}}
                           />
                    }     
                          
                      
                    </div>

                    <button className="login-button"
                    onClick={() => setShowForgotPassword(false)}
                    type="button"
                    >{t('LOGIN.Back to Login')}
                    </button>



                  </div>   

                    

                    

                </div>
            </>
        )}

    

        {/* Toast Notification */}
        <Toast ref={toast} position='top-right' className='login-toast' style={{ zIndex: 9 }} />
    </>
);

}