import { Vector } from 'ol/source';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { MapContext } from '../MapContext';
import { Snap } from 'ol/interaction';
import axios from 'axios';
import VectorSource from 'ol/source/Vector';
import { useAppSelector } from '../../../../../state/hooks';
import { Project } from '../../../../../util/model/project';
import { RootState } from '../../../../../state/store';
import Modify from 'ol/interaction/Modify';
import { Toast } from 'primereact/toast';
import { DrawStateType } from '../../../../../util/model/drawState';
import { DragBox, Select } from 'ol/interaction';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { useDispatch } from 'react-redux';
import { setSelectedFeature } from '../../../../../state/features/selectedFeatureSlice';
import { Sidebar } from '../../../../layouts/sidebar/Sidebar';
import { setProjects,setLayerGroups,setEnabledGroupAlias,setExternalLayerExtent,setIsLayerVisible,setServices,setStyles,setOfflines,setSelectedKeysforPano,setSelectedLayerTable,setSelectedLayerGeoserverUrlTable,setLayerNodes,setVisibleDomains,setSelectedLayer, setSelectedLayerGeoserverUrl, setWorkspace } from '../../../../../state/features/layerSlice';
import { MapBrowserEvent } from 'ol';
import BaseEvent from 'ol/events/Event';
import Feature from 'ol/Feature';
import { Translate } from 'ol/interaction';
import { ButtonState } from '../../../../shared/button-state/ButtonState';


interface IProps {
    layername: string,
    visible: boolean,
    url_workspace: string
}


interface LayerRegistry {
    [key: string]: {
        layer: VectorLayer<any>;
        source: VectorSource<any>;
    };
}



function BoxSelection(props: PropsWithChildren<IProps>) {

    const { layername, visible, url_workspace} = props;
    const map = useContext(MapContext);
    const dispatch = useDispatch()
    const t = useRef<VectorLayer<any> | null>(null);
    const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
    const [isLoaded, setIsLoaded] = useState(false)
    const [snapInteraction, setSnapInteraction] = useState<any>(undefined)
    const [dragBoxInteraction, setDragBoxInteraction] = useState<DragBox | null>(null);
    const [selectInteraction, setSelectInteraction] = useState<Select | null>(null);
    const drawState = useAppSelector(state => state.drawState.value)
    const buttonState = useAppSelector(state => state.buttonState.buttonState);
    const [features, setFeatures] = useState<any[]>([]);
    const toast = useRef<any>();
    const services: any = useAppSelector(state => state.layer.services)
    const [source2, setSource2] = useState<VectorSource<any>>(new VectorSource())
    const [source3, setSource3] = useState<VectorSource<any>>(new VectorSource())
    const LayerRegistry = useRef<LayerRegistry>({});
    const selectedLayer: any = useAppSelector((state: RootState) => state.layer.selectedLayer);
    const selectedUrlWorkSpace: any = useAppSelector((state: RootState) => state.layer.workspace);
    const selectedgroups: any = useAppSelector((state: RootState) => state.layer.layerGroups);
    const selectedtablelayer: any = useAppSelector((state: RootState) => state.layer.selectedLayerTable);
    const VisibleLayer: any = useAppSelector((state: RootState) => state.layer.isLayerVisible);
    const LayerNodes: any = useAppSelector((state: RootState) => state.layer.layerNodes);
    const [isDragBoxActive, setIsDragBoxActive] = useState(false);
    const [select, setSelect] = useState<Select | null>(null);
    const selectedFeatures: any = useAppSelector(state => state.selectedFeature.feature);
    const selectedGeoServerUrl: any = useAppSelector((state: RootState) => state.layer.geoserverUrl);
    
    const getActiveLayer = (layername:any) => {
            return LayerRegistry.current[layername]; // Returns { layer, source } or undefined if not found
    };
    const getActiveSource = (layername:any) => {
    const activeLayer = getActiveLayer(layername);
            return activeLayer ? activeLayer.source : null; // Returns the source or null if not found
    };

    const getLayerFromFeature = (feature:any) => {
        const activeSource = getActiveSource(layername); // Assuming this function retrieves the current active source
        if (!activeSource) return null;

        // Check if the feature belongs to the active source
        const features = activeSource.getFeatures();
        if (features.includes(feature)) {
            // If it does, return the layer associated with this source
            return LayerRegistry.current[layername]?.layer; // Assuming layername is accessible here
        }else{
/*             console.log('Feature is not in the layer') 
*/        
             return;
            }
    };


    



    useEffect(() => {

        const handleClick = (event: MapBrowserEvent<MouseEvent>) => {
          const isShiftKeyPressed = event.originalEvent.shiftKey;
          const geoJsonFormat = new GeoJSON();
          let featureClicked = false;

          if (isShiftKeyPressed && selectedFeatures.length > 0) {
            let updatedFeatures = [...selectedFeatures];
            let featureRemoved = false;

            map.forEachFeatureAtPixel(event.pixel, (feature: any) => {
              if (feature instanceof Feature) {
                featureClicked = true;
                const featureId = feature.getId();
                const initialLength = updatedFeatures.length;
                updatedFeatures = updatedFeatures.filter((f: any) => f.id !== featureId);

                if (updatedFeatures.length < initialLength) {
                  featureRemoved = true;
                  return true; // Stop at first removed feature
                }
              }
            });

            if (featureRemoved) {
              dispatch(setSelectedFeature(updatedFeatures));
            }


          }else if (event.originalEvent.ctrlKey && selectedFeatures.length >= 0){
            const newFeatures:any = []
            map.forEachFeatureAtPixel(event.pixel, (feature:any) => {
                if(feature instanceof Feature){
                    featureClicked = true;
                    const featureJson = geoJsonFormat.writeFeatureObject(feature);
                    if(!selectedFeatures.some((f:any)=> f.id === featureJson.id)){
                        newFeatures.push(featureJson);
                    }
                }
            });  
          const updatedFeatures = [...selectedFeatures, ...newFeatures];
          dispatch(setSelectedFeature(updatedFeatures));
        }

        if(!featureClicked){
            dispatch(setSelectedFeature([]));
        }

        };

        map.on('click', handleClick);

        return () => {
          map.un('click', handleClick);
        };
      }, [map, selectedFeatures, dispatch]);




    useEffect(() => {
        // Initialize select interaction
        const SelectionLayer = getActiveLayer(layername);

        const select = new Select({
            multi: true,
            layers: [selectedLayer.layer],
        });

        setSelect(select);




        // Initialize dragBox interaction
        const dragBox = new DragBox({



            condition: (event: MapBrowserEvent<MouseEvent>) => {
                if (!event.originalEvent) return false;

                const browserEvent = event.originalEvent;
                const isCtrlPressed = browserEvent.ctrlKey || false;
                const isShiftPressed = browserEvent.shiftKey || false;

                // Regular dragbox with Ctrl
                if (isCtrlPressed && !isShiftPressed) {
                    return true;
                }

                // Additive selection with Ctrl + Shift
                if (isCtrlPressed && isShiftPressed) {
                    return true;
                }

                return isCtrlPressed && (!isShiftPressed || isShiftPressed);
            }
        });

        setSelectInteraction(select);
        setDragBoxInteraction(dragBox);

        map.addInteraction(select);
        map.addInteraction(dragBox);







        // Handle dragBox end event
        dragBox.on('boxend', () => {


            const mapViewport = map.getViewport();
            const win = mapViewport.ownerDocument.defaultView || window;
            const browserEvent = win.event as MouseEvent;

            const isCtrlPressed = browserEvent?.ctrlKey || false;
            const isShiftPressed = browserEvent?.shiftKey || false;

            const extent = dragBox.getGeometry().getExtent();


            const activeLayer = getActiveLayer(layername);
            // Use LayerRegistry to get the correct source
            const activeSource = activeLayer.source;
            if (!activeSource) return;

            // Only get features if this is the selected layer
            const boxFeatures = activeSource.getFeaturesInExtent(extent).filter(
                (feature) => !selectedFeatures.includes(feature) && feature.getGeometry().intersectsExtent(extent) && selectedLayer.name == layername
            );
            
            const newFeaturesForRedux = boxFeatures.map((feature:any) => {
                const geojsonFormat = new GeoJSON();
                return geojsonFormat.writeFeatureObject(feature);
            });

            const geojsonFormat = new GeoJSON();
            const newFeatures = boxFeatures.map(feature => {
                const geojsonFeature = geojsonFormat.writeFeatureObject(feature);
                return geojsonFormat.readFeature(geojsonFeature);
            });

            // getting the ids of the selected features
            const selectedFeatureIds = selectedFeatures.map((feature:any) => feature.id);

            const TSelectedID = selectedFeatureIds[0]?.split('.')[0]

            // getting the ids of the new features
            const newFeatureIds = newFeaturesForRedux.map((feature:any) => feature.id)

            const TNewFeatID = newFeatureIds[0]?.split('.')[0] || null

            if (isCtrlPressed && isShiftPressed) {

                if (TSelectedID === TNewFeatID) {

                    dispatch(setSelectedFeature([]));

                    const combinedFeatures = [...selectedFeatures, ...newFeaturesForRedux];
                    const uniqueFeatures = Array.from(
                        new Set(combinedFeatures.map(f => JSON.stringify(f)))
                    ).map(str => JSON.parse(str));

                    dispatch(setSelectedFeature(uniqueFeatures));

                    const existingFeatures = select.getFeatures().getArray();
                    select.getFeatures().clear();
                    [...existingFeatures, ...newFeatures].forEach(feature => {
                    select.getFeatures().push(feature);

                });

                }else {

                    dispatch(setSelectedFeature([]));
                    dispatch(setSelectedFeature(newFeaturesForRedux));

                    select.getFeatures().clear();
                newFeatures.forEach(feature => {
                    select.getFeatures().push(feature);

                });


                } 
            } else if(isCtrlPressed && !isShiftPressed){
                if (selectedLayer.name === layername) {

                  dispatch(setSelectedFeature([]));

                    dispatch(setSelectedFeature(newFeaturesForRedux));

                    select.getFeatures().clear();
                newFeatures.forEach(feature => {
                    select.getFeatures().push(feature);
                });

            }

                }
                // clear the selection of selected features


        });




        return () => {
            if (map) {
                map.removeInteraction(select);
                map.removeInteraction(dragBox);
            }
        };
    }, [map,  dispatch, selectedFeatures, selectedLayer, layername,]);

    // Your existing WFS loading code
    useEffect(() => {

        const token = localStorage.getItem('token');
        const { REACT_APP_BASE_URL } = process.env;

        const currentExtent = map.getView().calculateExtent(map.getSize());
        const buffer = 1000;
        const extendedExtent = [
        currentExtent[0] - buffer, // minX
        currentExtent[1] - buffer, // minY
        currentExtent[2] + buffer, // maxX
        currentExtent[3] + buffer  // maxY
        ];
        const extendedExtent4326 = extendedExtent;// transformExtent(extendedExtent, 'EPSG:3857', 'EPSG:4326');
        const url = REACT_APP_BASE_URL + `rest/v1/geoserver/${selectedUrlWorkSpace}/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=` +
            layername + `&bbox=${extendedExtent4326.join(',')},EPSG:3857` + (token ? `&token=${token}` : '');


        axios.get(url).then(response => {
            const type = response?.data.type;
            const featureCollection = response?.data;

            if (type === "FeatureCollection" && featureCollection && !featureCollection.length) {
                const geojson = new GeoJSON();
                const features: any = geojson?.readFeatures(featureCollection);
                setFeatures(features);
                let vectorSource = new Vector();
                vectorSource.addFeatures(features);
                let vector = new VectorLayer({
                    source: vectorSource,
                    visible: visible,
                    zIndex: 100000,
                    opacity: .001
                });

                setSource(vectorSource);

                LayerRegistry.current[layername] = {
                    layer: vector,
                    source: vectorSource
                };

                setIsLoaded(true)

                t.current = vector;
                map.addLayer(vector);
            }
        }).catch((e: any) => {
            toast.current?.show({ severity: 'error', summary: 'Error Message', detail: "Digitization failed!", life: 3000 });
        })

        return () => {
            const intrcts = (window as any).olMap.getInteractions().getArray().filter((interaction:any)=>interaction instanceof Snap);
            const _snapInteraction = intrcts.find((interaction:any)=>interaction.getProperties().name === layername);
            if(_snapInteraction){
                (window as any).olMap.removeInteraction(_snapInteraction);
            }
            map.removeLayer(t.current!);
        };
    }, [ drawState,selectedUrlWorkSpace]);


    useEffect(() => {
        const vectorLayer = t.current!;
        if (vectorLayer) {
            vectorLayer.setVisible(visible);
        }
    }, [visible])
    useEffect(() => {
        setTimeout(() => {
            const isSnapActive = (drawState.drawStateType !== DrawStateType.NONE || (window as any).buttonState === 'VERTEX' || (window as any).buttonState === 'ADD') && visible && isLoaded
            if (isSnapActive) {
                const snap = new Snap({
                    source: source,
                })
                const intrcts : any= (window as any).olMap.getInteractions().getArray().filter((interaction:any)=>interaction instanceof Snap)
                const control : any= intrcts.find((interaction:any)=>interaction.getProperties().name === layername)
                if(!control){
                    snap.setProperties({"name":layername})
                    map.addInteraction(snap)
                    setSnapInteraction(snap);
                }   
            } 
        }, 200);
    }, [isLoaded, visible, drawState, buttonState])

    return (
        <>
            <Toast ref={toast} position='bottom-center' style={{ zIndex: '9 !important' }} />
            {selectedFeatures.length !== 0 && buttonState === ButtonState.DELETE}
        </>
    )
}

export default BoxSelection;