import { useEffect, useRef, useState } from "react"
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from "react-router-dom";
import { LoginRestApi } from "../../util/restapi/login";
import { AxiosError } from "axios";
import { error } from "console";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import './ForgotPassword.scss'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { ProgressBar } from 'primereact/progressbar';
import { Message } from 'primereact/message';
import { useAppSelector } from '../../state/hooks';
import { useTranslation } from 'react-i18next';
import { ValidateRestApi } from '../../util/restapi/validate';
import { setCurrentLanguage } from '../../state/features/languageSlice';
import { setCustomize, setToken } from '../../state/features/customizeSlice';
import { CustomizeRestApi } from '../../util/restapi/customize';
import { useDispatch } from 'react-redux';
import { setShowLoading } from '../../state/features/loadingSlice';
import LoadingScreen from '../../components/shared/loading/LoadingScreen';
import 'primeicons/primeicons.css';





// Edit this component (add inputs, validation and ex.)

const ForgotPassword = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const customize: any = useAppSelector((state: any) => state.customize.value)
    const toast = useRef<any>();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [tokenFromUrl, setTokenFromUrl] = useState<string | null>(params.get('token'));
    const [activate, setActivate] = useState<Boolean>(false)
    const [redirectLoginPopupVisible, setRedirectLoginPopupVisible] = useState<boolean>(false)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [newPassword, setNewPassword] = useState('');




    const defaultValues = {
        password: '',
        confirmPassword: ''
    };


    const { control, formState: { errors }, handleSubmit, watch } = useForm({ defaultValues });
    const password = watch('password');
    const confirmPassword = watch('confirmPassword');
    

    const validatePassword = (value:any) => {
        if (value.length < 8) {
            return t('LOGIN.Password must be at least 8 characters');
        }
        return true;
    };


    const onSubmit = (data: any) => {

        if (data.password === data.confirmPassword && data.password.length >= 8) {
            dispatch(setShowLoading(true))
            LoginRestApi.changePasswordAfterForgot({ password: data.password }, tokenFromUrl)
                .then((res) => {
                        setTimeout(() => {
                            dispatch(setShowLoading(false))

                            if (res.data.success) {
                                toast.current?.show({ 
                                    severity: 'success', 
                                    detail: (t('MESSAGE.Password was reset successfully')), 
                                    life: 10000  
                                });
                            }
                        }, 4000);
                })
                .catch((error: AxiosError) => {
                    dispatch(setShowLoading(false))
                    toast.current?.show({ 
                        severity: 'error', 
                        detail: error?.response?.data?.message, 
                        life: 3000 
                    });
                })
        }
    };

    const updatePasswordStrength = (value:any) => {
        return value.length >= 8 ? 100 : Math.min((value.length / 8) * 100, 100);
    };

    const getCustomize = async () => {
        try {

          dispatch(setShowLoading(true))

          const response = await CustomizeRestApi.customize()
          dispatch(setCustomize(response.data.customize));
        } catch (error) {
            dispatch(setShowLoading(false))


        }  finally {
            dispatch(setShowLoading(false));  

        }
      }

      const licenseValidate = async () => {
        try {
          await ValidateRestApi.validate()
        } catch (error) {
        }
      }
    

    const header = (
        <div className='right'>
            <img src={customize.logo} alt="ankageo-logo" width='54%' margin-leftt='50px' />
        </div>
    );

    useEffect(() => {
        // if(logoff){
        //     window.location.reload();
        // } 
        getCustomize()
        licenseValidate()
        localStorage.setItem('activeLanguage', +(window as any).innerWidth <= 640 ? 'eng' : localStorage.getItem('activeLanguage') || 'tr');
    
        if(+(window as any).innerWidth <= 640){
            i18n.changeLanguage("eng");
            dispatch(setCurrentLanguage("eng"))
        }
    
        // dispatch(setLogOff(false)); 
      }, [])




    const checkForgotPassword = async (token: string | null) => {
        return LoginRestApi.checkForgotPassword(token)
    }


    const changePasswordReq = async () => {
        LoginRestApi.changePasswordAfterForgot({ password: newPassword }, tokenFromUrl).then((res) => {
            dispatch(setShowLoading(true))

            if (res.data.success) {
                toast.current?.show({ severity: 'success', detail: (t('MESSAGE.Password reset. You must login')), life: 5000 });
                dispatch(setShowLoading(false))

            }
            setRedirectLoginPopupVisible(true)
        }).catch((error: AxiosError) => {
            toast.current?.show({ severity: 'error', detail: error?.response?.data?.message, life: 3000 });
            dispatch(setShowLoading(false));

        }).finally(() => {
            dispatch(setShowLoading(false));

        });
    }


    useEffect(() => {
        if (!tokenFromUrl) {
            navigate('/login', { replace: true })
        }
        dispatch(setShowLoading(true))

        checkForgotPassword(tokenFromUrl).then((res) => {
            if (res.data.success) {
                setActivate(true)
            }
            else {
                navigate('/login', { replace: true })
            }
        }).catch((error: AxiosError) => {
            navigate('/login', { replace: true })
            dispatch(setShowLoading(false))


        }).finally(() => {
            dispatch(setShowLoading(false));  

        });

    }, [])
    return <>

    <Toast ref={toast} />

     

     <LoadingScreen/>

     <div className="Reset-container" style={{ background: 'var(--surface-ground)' }}>
            
            <div className="Reset-card-container">

            <div className='right'>

               <img src={customize.logo} alt="ankageo-logo" width='54%' margin-leftt='50px' />

            </div>
               
                
                <form onSubmit={handleSubmit(onSubmit)} className="Reset-form">

                <h2 className="create-newpass-text">{t('LOGIN.Change your Password')}</h2>
                <span>{t('LOGIN.Set your new Password')}</span>

                    <div className="field">
                        <Controller
                            name="password"
                            control={control}
                            rules={{ 
                                required: t(('LOGIN.Password is required')),
                                validate: validatePassword,
                                maxLength: { value: 12, message: 'Password cannot exceed 12 characters' }
                            }}
                            render={({ field, fieldState }) => (
                                <span className="p-input-icon-right w-full">

                                    <i className="pi pi-lock"/>
                                    <i className="pi pi-eye-slash cursor-pointer" 
                                       onClick={() => setPasswordVisible(prev => !prev)} />
                                    <InputText
                                        id={field.name}
                                        {...field}
                                        type={passwordVisible ? "text" : "password"}
                                        placeholder={t('LOGIN.Password')}
                                        className={classNames('w-full', { 'p-invalid': fieldState.invalid })}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setPasswordStrength(updatePasswordStrength(e.target.value));
                                            setNewPassword(e.target.value);
                                        }}
                                        maxLength={12}
                                    />
                                </span>
                            )}
                        />
                        {errors.password && <small className="p-error">{errors.password.message}</small>}
                    </div>


                    
                    <div className="field">
                        <Controller
                            name="confirmPassword"
                            control={control}
                            rules={{
                                required: t(('LOGIN.Please Confirm your password')),
                                validate: value => value === password || t('LOGIN.Passwords do not match')
                            }}
                            render={({ field, fieldState }) => (
                                <span className="p-input-icon-right w-full">
                                    
                                    <i className="pi pi-lock"/>
                                    <i className="pi pi-eye-slash cursor-pointer" 
                                       onClick={() => setConfirmPasswordVisible(prev => !prev)} />
                                          
                                    <InputText
                                        id={field.name}
                                        {...field}
                                        type={confirmPasswordVisible ? "text" : "password"}
                                        placeholder={t('LOGIN.Confirm Password')}
                                        className={classNames('w-full', { 'p-invalid': fieldState.invalid })}
                                        maxLength={12}
                                    />
                                </span>
                            )}
                        />
                        {errors.confirmPassword && <small className="p-error">{errors.confirmPassword.message}</small>}
                    </div>



                    <div className="Buttons-Container">

                    <button  type="submit" className="Submit-button">{t('LOGIN.Reset Password')}</button>

                    <button className="login-button"
                    onClick={() => navigate('/login', { replace: true })}
                    type="button"
                >{t('LOGIN.Go Back to Login')}
                </button>

                  </div>


                </form>
            </div>
        </div>
        
    </>
}

export default ForgotPassword;