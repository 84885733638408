import { useTranslation } from 'react-i18next';
import './LoadingScreen.scss'
import { useAppSelector } from '../../../state/hooks';

export default function LoadingScreen() {
  const { t } = useTranslation();
  const showLoading = useAppSelector(state => state.loading.showLoading);
  const customize = useAppSelector(state => state.customize.value);

  return (
    <>
      {
        showLoading &&
        <div className='loading-screen'>
          <div className='loading-container'>
            <img className='loading-icon' src={customize.logo} alt='logo'/>
            <h1 className='loading-text'>{t('TEXT.Please Wait')}</h1>
          </div>
        </div>
      }
    </>
  )
}


