import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const getToken = () => {
    return localStorage.getItem('token')
}

async function login(username: string, password: string) {
    return axios.post(REACT_APP_BASE_URL + 'rest/v1/auth/login', { username, password });
}

const loginUser = async () => {
    return axios.get(REACT_APP_BASE_URL + 'rest/v1/user/me', { params: { token: getToken() } });
};

async function checkForgotPassword(token: string | null) {
    return axios.get(REACT_APP_BASE_URL + "rest/v1/auth/checkForgotPassword", { params: { token } })
}
async function sendUrlForForgotPasswordToMail(mail: string) {
    return axios.get(REACT_APP_BASE_URL + `rest/v1/auth/forgotPassword/${mail}`)
}
async function changePassword(body: { oldPassword: string, newPassword: string }) {
    return axios.put(REACT_APP_BASE_URL + `rest/v1/user/changePass`, { ...body }, { params: { token: getToken() } })
}
async function changePasswordAfterForgot(body: { password: string }, token: string | null) {
    return axios.put(REACT_APP_BASE_URL + `rest/v1/auth/changePassAfterForgot`, { ...body }, { params: { token } })
}


export const LoginRestApi = {
    login,
    loginUser,
    checkForgotPassword,
    sendUrlForForgotPasswordToMail,
    changePassword,
    changePasswordAfterForgot
}